<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    :loading="isLoading"
    close-button
  >
    <template v-slot:title>
      <p class="text-darkPurple">{{ slug }} Timeline</p>
    </template>
    <template v-slot:subtitle>
      <employee-details :user-details="employeeData" />
    </template>
    <div>
      <div class="flex flex-col pt-2 space-y-5">
        <div class="flex">
          <div class="w-5/12">
            <span
              class="w-6/12 inline-block text-xs text-romanSilver font-semibold uppercase"
            >
              Category:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{ timelineData.paySetting }}
            </span>
          </div>
          <div class="w-7/12 pl-3 border-l ml-2">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Request Type:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{ timelineData.advanceType }}
            </span>
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Intrest Rate:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{ timelineData.rate }}%
            </span>
          </div>
          <div class="w-7/12 pl-3 border-l ml-2">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Repayment Period:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{ timelineData.duration }} Month(s)
            </span>
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Amount Req:
            </span>
            <span
              class="text-sm text-darkPurple font-semibold w-full break-words"
            >
              {{ convertToCurrency(timelineData.amount) }}
            </span>
          </div>
          <div class="w-7/12 pl-3 border-l ml-2">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              First Repayment:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{
                $DATEFORMAT(new Date(timelineData.repaymentTime), "MMMM yyyy")
              }}
            </span>
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Outstanding:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{ convertToCurrency(timelineData.outstandingAmount) }}
            </span>
          </div>
          <div class="w-7/12 pl-3 border-l ml-2">
            <span
              class="inline-block text-xs text-romanSilver font-semibold uppercase w-6/12"
            >
              Request Date:
            </span>
            <span class="text-sm text-darkPurple font-semibold w-full">
              {{
                $DATEFORMAT(new Date(timelineData.requestDate), "dd-MM-yyyy")
              }}
            </span>
          </div>
        </div>
        <div class="pt-3">
          <span
            class="inline-block text-xs text-romanSilver font-semibold uppercase"
            >Approval Status:</span
          >
          <span
            class="mx-2 py-1 px-3 rounded text-sm font-semibold capitalize"
            :class="bgColor(timelineData.status)"
          >
            {{ timelineData.status }}
          </span>
        </div>
        <div class="border border-dashed" />
        <div class="overflow-y-visible">
          <div class="flex flex-col py-1 px-2">
            <card
              class="w-full py-4 mb-3 px-3 space-y-3"
              v-for="(lists, index) in timelineData.repaymentSchedule"
              :key="index"
            >
              <div>
                <span
                  class="inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Month:
                </span>
                <span
                  class="text-darkPurple font-semibold text-base float-right"
                >
                  {{ $DATEFORMAT(new Date(lists.scheduleDate), "MMMM yyyy") }}
                </span>
              </div>
              <div>
                <span
                  class="inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Repayment amount:
                </span>
                <span
                  class="text-darkPurple font-semibold text-base float-right"
                >
                  {{ convertToCurrency(lists.amount) }}
                </span>
              </div>
              <div>
                <span
                  class="inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Status:
                </span>
                <span class="inline-block text-base float-right">
                  <span
                    class="bage px-2 py-1 rounded text-sm font-semibold capitalize"
                    v-if="lists.status === 'approved'"
                  >
                    {{ lists.status }}
                  </span>
                  <span
                    class="bg-Pend px-2 py-1 rounded text-sm font-semibold capitalize"
                    v-else
                  >
                    {{ lists.status }}
                  </span>
                </span>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import Card from "@/components/Card";
import RightSideBar from "@/components/RightSideBar";
import EmployeeDetails from "@/modules/Admin/EmployeeModule/EmployeeDirectory/sideActions/employeeDetails";

export default {
  name: "AdvanceTimeline",
  components: { EmployeeDetails, RightSideBar, Card },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    timelineData: {
      type: Object,
      default() {
        return {};
      },
    },
    slug: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      employeeData: {},
      isLoading: true
    };
  },
  methods: {
    bgColor(data) {
      if (data === "pending") {
        return "bg-Pend";
      }

      if (data === "approved") {
        return "bg-Appr";
      }

      if (data === "disapproved") {
        return "text-desire bg-light-flame-red";
      }

      return {};
    },
  },
  created() {
    this.$_getOneEmployeeAll({
      id: this.timelineData.userId,
    }).then(({ data }) => {
      this.employeeData = data;
      this.isLoading = false;
    });
  },
};
</script>

<style scoped></style>
